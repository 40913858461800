import { useCallback, useEffect, useState } from 'react';

let logoutTimerID;

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});

  const login = useCallback((user = {}) => {
    setIsLoggedIn(true);
    setUserData(user);
    const { expiresIn = 60 * 60, expiration } = user;
    user.expiration = expiration ||
      new Date(Date.now() + expiresIn * 1000).getTime();
    localStorage.setItem('userData', JSON.stringify(user));
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
    setUserData({});
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    if (userData.token) {
      const remaining = userData.expiration - Date.now();
      logoutTimerID = setTimeout(logout, remaining);
    } else {
      clearTimeout(logoutTimerID);
    }
  }, [logout, userData]);

  useEffect(() => {
    const storedUser = localStorage.getItem('userData');
    const userData = JSON.parse(storedUser) || {};
    const { token, expiration } = userData;
    const notExpired = new Date(expiration) > Date.now();
    if (token && notExpired) {
      login(userData);
    }
  }, [login]);

  return { isLoggedIn, login, logout, userData };
};
