import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import Avatar from '../UIElements/Avatar';
import { AuthContext } from '../../context/auth-context';
import { ASSET_URL } from '../../../constants';
import './NavLinks.css';

const NavLinks = props => {
  const { isLoggedIn, logout, userData } = useContext(AuthContext);
  const { id, image, fullname } = userData;
  const avatarUrl = new URL(image, ASSET_URL);

  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/" exact>All Users</NavLink>
      </li>
    { isLoggedIn &&
      <li>
        <NavLink to={`/${id}/places`}>My Places</NavLink>
      </li>
    }
    { isLoggedIn &&
      <li>
        <NavLink to="/places/new">Add Place</NavLink>
      </li>
    }
    { !isLoggedIn &&
      <li>
        <NavLink to="/login">Log In</NavLink>
      </li>
    }
    { isLoggedIn &&
      <li>
        <button onClick={logout}>Log Out</button>
      </li>
    }
    { isLoggedIn && 
      <li>
        <button disabled className="nav-links__avatar">
          <Avatar image={avatarUrl} alt={fullname} />
          { fullname }
        </button>
      </li>
    }
    </ul>
  );
};

export default NavLinks;