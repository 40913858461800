const {
  REACT_APP_API_ORIGIN: apiBase,
  REACT_APP_ASSET_URL,
  REACT_APP_MAP_API_KEY
} = process.env;
const placesApi = new URL('/api/places', apiBase);
const usersApi = new URL('/api/users', apiBase);

const constants = {
  API_BASE: apiBase,
  API_HEADERS: {
    'Content-Type': 'application/json'
  },
  ASSET_URL: REACT_APP_ASSET_URL,
  MAP_API_KEY: REACT_APP_MAP_API_KEY,
  PLACES_API: placesApi,
  USERS_API: usersApi
};
console.log('🚀 ~ file: constants.js ~ line 17 ~ constants', constants)

module.exports = Object.freeze(constants);