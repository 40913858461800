import React from 'react';

import './Avatar.css';

const Avatar = props => {
  const { alt, className = '', image, style, width } = props;
  return (
    <div className={`avatar ${className}`} style={style}>
      <img
        src={image}
        alt={alt}
        style={{ width, height: width }}
      />
    </div>
  );
};

export default Avatar;
